import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { withIntl } from "../i18n"

import { Carousel } from "react-responsive-carousel"
import Html from '../components/Html'
import SEO from '../components/seo'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./designer.css"

const Designer = ({ data }) => (
  <Layout>
    <SEO title={data.contentfulDesigner.name} />
    <div className="designer-split">
      <Carousel
        infiniteLoop
        showStatus={false}
        showThumbs={false}
      >
        {data.contentfulDesigner.images.map(image => (
          <img
            key={image.fluid.src}
            sizes={image.fluid.sizes}
            src={image.fluid.src}
            srcSet={image.fluid.srcSet}
            alt=""
            className="cover"
          />
        ))}
      </Carousel>
      <div className="designer-details">
        <h1>{ data.contentfulDesigner.name } </h1>
        <Html html={data.contentfulDesigner.content.childContentfulRichText.html} />
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulDesigner (id: { eq: $id }, node_locale: { eq: $locale }) {
      name
      node_locale
      content {
        childContentfulRichText {
          html
        }
      }
      images {
        fluid {
          sizes
          src
          srcSet
        }
      }
    }
  }
`

export default withIntl(Designer)
